import Cookies from "../js.cookie";

let __DFP_slots = {};
let __hidden_slots = [];
let __active_slots = [];

const HPDesktopParams = {
  mediaTypes: {
    banner: {
      sizes: [[300, 250]],
    },
  },
  bids: [
    {
      bidder: "rubicon",
      params: {
        zoneId: 1215118,
        siteId: 245008,
        accountId: 10468,
      },
    },
    {
      bidder: "rubicon",
      params: {
        zoneId: 1387450,
        siteId: 245008,
        accountId: 10468,
      },
    },
    {
      bidder: "rubicon",
      params: {
        zoneId: 1387452,
        siteId: 245008,
        accountId: 10468,
      },
    },
    {
      bidder: "criteo",
      params: {
        networkId: 8179,
      },
    },
  ],
  sizes: [[300, 250]],
};

function getAdUnitCode(adItem) {
  let unitCode = "";

  if (adItem.containerId.startsWith("mobile-ad-")) {
    unitCode = "/5856/wash.times/mobile-ad-0";
  } else {
    unitCode = "/5856/" + adItem.slotId;
  }

  console.log("__ADS ad unit code: ", unitCode);
  return unitCode;
}

function defineDFPSlot(adItem, refresh = false) {
  //
  // Define DFP slots
  //

  let slot = null;
  let unitName = null;

  googletag.cmd.push(function () {
    if (adItem.outOfPage) {
      slot = googletag.defineOutOfPageSlot(
        "/5856/" + adItem.containerId,
        adItem.containerId
      );
    } else {
      console.log("__ADS DEFINESLOT with code: ", getAdUnitCode(adItem));
      slot = googletag
        .defineSlot(getAdUnitCode(adItem), adItem.sizes, adItem.containerId)
        .setCollapseEmptyDiv(true);
    }

    // pub tags

    slot.addService(googletag.pubads());

    if (GPT.SFP && settings.platform === "mobile") {
      slot.setTargeting("strnativekey", GPT.SFP);
    }
    if (window.xp1_auds && window.xp1_auds.Nodes) {
      slot.setTargeting("demographics", window.xp1_auds.Nodes.join(","));
    } else if (window.GPT.demographics) {
      slot.setTargeting("demographics", window.GPT.demographics);
    }
    if (GPT.SITE) {
      slot.setTargeting("site", GPT.SITE);
    }
    if (GPT.cats) {
      slot.setTargeting("cat", GPT.cats);
    }
    if (GPT.tag) {
      slot.setTargeting("tag", GPT.tag);
    }
    if (GPT.type) {
      slot.setTargeting("type", GPT.type);
    }
    if (GPT.specials) {
      slot.setTargeting("special", GPT.specials);
    }
    if (GPT.slug) {
      slot.setTargeting("slug", GPT.slug);
    }
    if (GPT.contenttype) {
      slot.setTargeting("contenttype", GPT.contenttype);
    }
    if (GPT.contest) {
      slot.setTargeting("contest", GPT.contest);
    }
    if (GPT.camera) {
      slot.setTargeting("camera", GPT.camera);
    }
    if (GPT.awards) {
      slot.setTargeting("awards", GPT.awards);
    }

    __DFP_slots[adItem.containerId] = slot;

    if (refresh) {
      console.log("__ADS refresh slot", slot);
      googletag.pubads().refresh([slot]);
      __active_slots.push(slot);
    }
  });
}

const insertAdContainer = (elem, containerId, pageType) => {
  // TODO only insert if not already present
  elem.after(`<div id="${containerId}" class="inline-article-ad"></div>`);

  let use_prebid = null;
  let use_sizes = [[300, 250]];
  if (pageType === "homepage") {
    use_prebid = structuredClone(HPDesktopParams);
  } else if (pageType === "category" || pageType === "higher-ground-category") {
    use_prebid = structuredClone(HPDesktopParams);
  } else {
    use_prebid = structuredClone(HPDesktopParams);
  }
  let adUnitCode = null;
  if (containerId.startsWith("mobile-ad-")) {
    adUnitCode = "wash.times/mobile-ad-0";
  }
  use_prebid.code = containerId;
  __hidden_slots.push({
    mobileSlot: true,
    containerId: containerId,
    sizes: use_sizes,
    prebid: use_prebid,
    adUnitCode: adUnitCode,
  });
  console.log(
    `__ADS PREBID ${use_prebid.code} / ${containerId} / ${adUnitCode} to hidden slots`
  );
};

const orderedAdInserter = (contentItems, spacingFactor, pageType) => {
  // every <spacingFactor> elements, insert an ad container
  let arrayOfArrays = [];
  for (let i = 0; i < contentItems.length; i += spacingFactor) {
    arrayOfArrays.push(contentItems.slice(i, i + spacingFactor));
  }
  for (let n = 0; n < arrayOfArrays.length; n++) {
    let currentSet = arrayOfArrays[n];
    let lastArticle = currentSet[spacingFactor - 1];
    let containerId = `mobile-ad-${n}`;

    insertAdContainer($(lastArticle), containerId, pageType);
  }
};

let scrollHandlerActive = false;

export function loadAdsForMobile(contentElem = null) {
  console.log("__ADS loadAdsForMobile", contentElem);
  let spacingFactor = 4;
  let insertIndices = [];
  let articleItems = [];
  let contentSelector = null;
  scrollHandlerActive = false;
  __hidden_slots = [];
  googletag.cmd.push(() => {
    googletag.destroySlots(__active_slots);
    __active_slots = [];

    // CCPA / Privacy
    googletag.pubads().setPrivacySettings({
      restrictDataProcessing: false,
    });

    googletag.pubads().addEventListener("slotRequested", function (event) {
      var slot = event.slot;
      console.log("Slot", slot.getSlotElementId(), "has been requested.");
      var _slotElem = document.querySelector("#" + slot.getSlotElementId());
      // var _ph_text = _slotElem.querySelector('.ad-placeholder-text');
      // _ph_text.parentNode.removeChild(_ph_text);
      _slotElem.style.backgroundColor = "white";
    });
    googletag
      .pubads()
      .addEventListener("slotResponseReceived", function (event) {
        var slot = event.slot;
        console.log(
          "__ADS response for slot",
          slot.getSlotElementId(),
          slot.getResponseInformation()
        );
      });
  });

  if (contentElem) {
    contentSelector = $(contentElem);
  } else {
    contentSelector = $("body");
  }
  if (GPT.contenttype === "homepage") {
    articleItems = contentSelector.find("#center-column article");
    spacingFactor = 5;
    console.debug("__ADS selected items", articleItems);
    orderedAdInserter(articleItems, spacingFactor, "homepage");
    __adsforpage.forEach(function (adItem) {
      //skip the leaderboard slot on mobile, it is not used
      if (adItem.containerId === "div-gpt-ad-leaderboard") {
        return;
      }

      if (isElementInViewport($("#" + adItem.containerId))) {
        if (!__DFP_slots.hasOwnProperty(adItem.containerId)) {
          //
          // initialize ad slot with DFP, APS, Prebid
          //
          defineDFPSlot(adItem);

          executeParallelAuctionAlongsidePrebid(
            [
              {
                slotID: adItem.containerId,
                sizes: adItem.sizes,
                slotName: getAdUnitCode(adItem),
              },
            ],
            [adItem.prebid],
            __DFP_slots[adItem.containerId]
          );
        }
      } else {
        console.log("__ADS slot not visible", adItem.containerId);
        __hidden_slots.push(adItem);
      }
    });
  } else if (
    GPT.contenttype === "category" ||
    GPT.contenttype === "specials" ||
    GPT.contenttype === "higher-ground-category"
  ) {
    articleItems = contentSelector.find(".main-column .article-list article");
    orderedAdInserter(articleItems, spacingFactor, "category");
  } else if (
    GPT.contenttype === "story" ||
    GPT.contenttype === "higher-ground-story"
  ) {
    articleItems = contentSelector.find(".bigtext p");
    let viewPWidth = window.innerWidth || document.documentElement.clientWidth;
    if (viewPWidth > 500) {
      insertIndices = [9, 15, 21];
    } else {
      insertIndices = [9, 11, 13, 15, 17, 19];
    }

    for (let x = 0; x < insertIndices.length; x++) {
      let item = articleItems.get(insertIndices[x]);
      if (item) {
        insertAdContainer($(item), `mobile-ad-${x}`, "story");
      }
    }
  }

  if (!scrollHandlerActive) {
    $(window).on("scroll", checkHiddenSlots);
    scrollHandlerActive = true;
  }
}

const checkHiddenSlots = function () {
  //console.log("__ADS checkHidden", __hidden_slots);
  for (let i = 0; i < __hidden_slots.length; i++) {
    var adItem = __hidden_slots[i];
    //console.log("__ADS checking ", adItem.containerId);

    //
    // TODO keep a list of the elements to avoid reselecting each time
    //
    let vpCheck = isElementInViewport($("#" + adItem.containerId));
    if (vpCheck) {
      console.log("__ADS slot visible ", adItem.containerId);

      defineDFPSlot(adItem);

      executeParallelAuctionAlongsidePrebid(
        [
          {
            slotID: adItem.containerId,
            sizes: adItem.sizes,
            slotName: getAdUnitCode(adItem),
          },
        ],
        [adItem.prebid],
        [__DFP_slots[adItem.containerId]]
      );

      __hidden_slots.splice(i, 1);
    } else if (vpCheck === null) {
      __hidden_slots.splice(i, 1);
    }
  }
  if (!__hidden_slots.length) {
    console.log("__ADS removing scroll handler");
    $(window).off("scroll", checkHiddenSlots);
  }
};

const loadAdsForDesktop = function () {
  var APSslots = [];
  var prebidSlots = [];

  //check cookie for ads exclusion
  // if set, use exclude list
  var adFreeExclude = [];
  if (Cookies.get("onaip_") === undefined) {
    console.log("__ADS ||| no Piano, using all slots");
  } else {
    console.log("__ADS ||| using adFree exclude list");
    adFreeExclude = ["Desktop_MainPage_RR_2", "Desktop_RR_Articles_1"];
  }

  // https://developers.google.com/publisher-tag/guides/control-ad-loading
  /*

  The PubAdsService.refresh() method is used to populate a slot or slots with new ad content.
  This method can be used on slots that have yet to load any content (due to disableInitialLoad()), or
  to replace the contents of an already populated slot. However, only slots which have been registered by
  calling display() are eligible to be refreshed.

  */

  __adsforpage.forEach(function (adItem) {
    //
    // check ads exclusion list
    //
    if (adFreeExclude.includes(adItem.adUnitCode)) {
      if (adItem.adUnitCode === "Desktop_RR_Articles_1") {
        document.querySelector("#ad-primary-story-flex").style.height = 0;
      }
    } else {
      //
      // Is ad slot already visible?
      //
      if (isElementInViewport($("#" + adItem.containerId))) {
        if (!__DFP_slots.hasOwnProperty(adItem.containerId)) {
          //
          // initialize ad slot with DFP, APS, Prebid
          //
          defineDFPSlot(adItem);

          APSslots.push({
            slotID: adItem.containerId,
            sizes: adItem.sizes,
            slotName: getAdUnitCode(adItem),
          });

          adItem.prebid.code = adItem.containerId;
          console.log("__ADS PREBID code", adItem.prebid.code);
          prebidSlots.push(adItem.prebid);
        }
      } else {
        console.log("__ADS slot not visible", adItem.containerId);
        adItem.prebid.code = adItem.containerId;
        console.log("__ADS PREBID code", adItem.prebid.code);

        __hidden_slots.push(adItem);
      }
    }
  });

  $(window).on("scroll", checkHiddenSlots);

  googletag.cmd.push(function () {
    // !NOTE: These can be helpful in debugging ads loading and event sequencing
    //        Leave these here for reference and quick access

    googletag.pubads().addEventListener("slotOnload", function (event) {
      console.log("__ADS Slot has been loaded:", event.slot.getSlotElementId());
    });

    googletag
      .pubads()
      .addEventListener("slotResponseReceived", function (event) {
        var slot = event.slot;
        console.log(
          "__ADS response for slot",
          slot.getSlotElementId(),
          slot.getResponseInformation()
        );
      });

    googletag.pubads().addEventListener("slotRequested", function (event) {
      var slot = event.slot;
      console.log("Slot", slot.getSlotElementId(), "has been requested.");
      var _slotElem = document.querySelector("#" + slot.getSlotElementId());
      // var _ph_text = _slotElem.querySelector('.ad-placeholder-text');
      // _ph_text.parentNode.removeChild(_ph_text);
      _slotElem.style.backgroundColor = "white";
    });

    // googletag.pubads().addEventListener('slotVisibilityChanged', function(event) {
    //   var slot = event.slot;
    //   console.group('__ADS Visibility of slot', slot.getSlotElementId(), 'changed.');
    //
    //   // Log details of the event.
    //   console.log('__ADS Visible area:', event.inViewPercentage + '%');
    //   console.groupEnd();
    //
    //   if (event.inViewPercentage > 25) {
    //     var _slotElem = document.querySelector('#'+slot.getSlotElementId());
    //     var _ph_text = _slotElem.querySelector('.ad-placeholder-text');
    //     _ph_text.parentNode.removeChild(_ph_text);
    //     _slotElem.style.backgroundColor = "white";
    //   }
    // });

    // CCPA / Privacy
    googletag.pubads().setPrivacySettings({
      restrictDataProcessing: false,
    });

    //var isElectionsPage = window.location.href.match(/\/elections/);
    var isQuizPage = window.location.href.match(/\/quiz/);

    // out of page ad slot
    if (!isQuizPage) {
      if (Cookies.get("onaip_") === undefined) {
        console.log("__ADS defineOutOfPageSlot div-gpt-ad-oop");
        var oopSlot = googletag
          .defineOutOfPageSlot(GPT.path, "div-gpt-ad-oop")
          .setTargeting("pos", "oop")
          .setTargeting("contenttype", GPT.contenttype)
          .setTargeting("slug", GPT.slug)
          .setTargeting("cat", GPT.cats);

        oopSlot.addService(googletag.pubads());
        googletag.display(oopSlot);
        googletag.pubads().refresh([oopSlot]);
      } else {
        console.log("__ADS skipping OOP for Piano user");
      }
    }
  });

  // Fallback for prebid being blocked by AdBlock
  // only runs if AdBlock is present
  window.aax = window.aax || {};
  window.aax.cmd = window.aax.cmd || [];
  window.aax.cmd.push(function () {
    if (window.aax.getAbpStatus()) {
      window.googletag = window.googletag || {};
      window.googletag.cmd = window.googletag.cmd || [];
      window.googletag.cmd.push(function () {
        console.log("__ADS using AAX refresh");
        googletag.pubads().refresh();
      });
    }
  });

  googletag.cmd.push(function () {
    executeParallelAuctionAlongsidePrebid(
      APSslots,
      prebidSlots,
      Object.values(__DFP_slots)
    );
  });
};

window.loadAds = function () {
  console.log("__ADS load");
  if (TWT_CONTENT_ONLY) {
    console.log("__ADS __CONTENT content-only subscriber");
    return;
  }

  //initialize the apstag.js library on the page to allow bidding
  apstag.init({
    pubID: "6f8126c3-a155-4a34-b3e2-e7679af6a9ed",
    adServer: "googletag",
  });

  let viewPWidth = window.innerWidth || document.documentElement.clientWidth;
  if (viewPWidth < 800) {
    // Load ads if we're not in the mobile app
    //if (window.navigator.userAgent.toLowerCase() !== 'twtapp') {
    console.log(
      "__ADS viewport width is mobile, skipping mobile ad loader for testing"
    );
    loadAdsForMobile();
    //}
  } else {
    loadAdsForDesktop();
  }
  // increment session page view count
  var sessionPageViews = parseInt(Cookies.get("ads_spv")) || 0;
  sessionPageViews += 1;
  Cookies.set("ads_spv", sessionPageViews);
};

function executeParallelAuctionAlongsidePrebid(APS, PB, DFPslots) {
  // To feature-switch the Amazon APS or Prebid bidders, set aps / prebid to true and they'll be skipped
  var requestManager = {
    adserverRequestSent: false,
    aps: false,
    prebid: false,
  };

  // when both APS and Prebid have returned, initiate ad request
  function biddersBack() {
    if (requestManager.aps && requestManager.prebid) {
      sendAdserverRequest();
    }
    return;
  }

  // sends adserver request
  function sendAdserverRequest() {
    if (requestManager.adserverRequestSent === true) {
      return;
    }
    requestManager.adserverRequestSent = true;
    googletag.cmd.push(function () {
      console.log("__ADS refresh ads", DFPslots);
      googletag.pubads().refresh(DFPslots);
    });
  }

  // sends bid request to APS and Prebid
  function requestHeaderBids() {
    // APS request
    console.log("__ADS APS fetch bids", APS);

    apstag.fetchBids(
      {
        slots: APS,
        timeout: 2e3,
      },
      function (bids) {
        googletag.cmd.push(function () {
          console.log("__ADS APS callback, setDisplayBids");
          apstag.setDisplayBids();
          requestManager.aps = true; // signals that APS request has completed
          biddersBack(); // checks whether both APS and Prebid have returned
        });
      }
    );

    requestManager.aps = true;

    var priceGranularityBuckets = {
      buckets: [
        {
          min: 0,
          max: 22,
          increment: 0.05,
        },
      ],
    };

    // put prebid request here
    if (typeof pbjs !== "undefined") {
      if (!PB.length) {
        requestManager.prebid = true;
        console.log("__ADS no prebid slots, skipping");
      } else {
        pbjs.bidderSettings = {
          standard: {
            storageAllowed: true,
          },
        };
        pbjs.que.push(function () {
          console.log(
            "__ADS prebid setting price buckets and consent management",
            priceGranularityBuckets
          );
          pbjs.enableAnalytics({
            provider: "pianoDmp",
          });
          pbjs.setConfig({
            enableTIDs: true,
            consentManagement: {
              usp: {
                cmpApi: "iab",
                timeout: 100,
              },
              gdpr: {
                defaultGdprScope: false,
              },
              gpp: {
                timeout: 100,
              },
            },
            userSync: {
              filterSettings: {
                iframe: {
                  bidders: ["undertone", "openx", "rubicon", "criteo"],
                  filter: "include",
                },
              },
            },
            allowActivities: {
              accessDevice: {
                rules: [{ allow: true }],
              },
              transmitTid: {
                default: true,
              },
            },
            priceGranularity: priceGranularityBuckets,
            bidderTimeout: 3000,
            criteo: {
              fastBidVersion: "latest",
            },
          });
          console.log("__ADS PREBID BIDS: ", PB);
          pbjs.addAdUnits(PB);
          pbjs.requestBids({
            timeout: 3000,
            bidsBackHandler: function () {
              googletag.cmd.push(function () {
                console.log("__ADS prebid setTargetingForGPTAsync");

                /*
                function slotMatcher(slot) {
                  return function (adUnitCode) {
                    if (slot.getAdUnitPath().slice(6) === adUnitCode) {
                      console.log('__ADS slotmatch: ', slot.getAdUnitPath(), adUnitCode);
                      return true;
                    }
                    return false;
                  };
                }

                 */

                //TODO need the full map for container names here
                pbjs.setTargetingForGPTAsync(null, null);
                requestManager.prebid = true; // signals that Prebid request has completed
                // clear out ads from this auction
                pbjs.removeAdUnit();
                console.log("__ADS prebid cleared units for next auction");
                biddersBack(); // checks whether both APS and Prebid have returned
              });
            },
          });
        });
      }
    }
  }

  if(window.IS_ADS_HEADER_BIDDING_ENABLED) {
    requestHeaderBids();
  } else {
    sendAdserverRequest();
  }
}

function isElementInViewport(el) {
  // Special bonus for those using jQuery
  if (typeof jQuery === "function" && el instanceof jQuery) {
    el = el[0];
  }
  if (el === undefined) {
    return null;
  }

  var rect = el.getBoundingClientRect();
  var height = rect.bottom - rect.top;

  //console.log(rect.top, rect.left, rect.right, height, window.innerHeight, window.innerWidth);
  // console.log(
  //   rect.top >= 0,
  //   rect.left >= 0,
  //   (height >= 600 ? (rect.top + (height / 4)) : rect.top + (height / 2)) <= (window.innerHeight || document.documentElement.clientHeight),
  //   rect.right <= (window.innerWidth || document.documentElement.clientWidth) + 1);
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.top - 250 <=
      (window.innerHeight ||
        document.documentElement.clientHeight) /* or $(window).height() */ &&
    rect.right <=
      (window.innerWidth || document.documentElement.clientWidth) +
        1 /* or $(window).width() */
  );
}
